<template>
  <ion-page>
    <ion-header>
      <MainToolbar isSubpage :title="i18n.$t('tools.upload-reports.title')" />
    </ion-header>
    <ion-content>
      <ion-card v-if="anyFilePropertiesByPreviousVersion" color="warning" class="information-container">
        <ion-icon :icon="informationCircleOutline" size="large"></ion-icon>
        <div>{{ i18n.$t('tools.upload-reports.previous_version_warning') }}</div>
      </ion-card>
      <ion-list>
        <ion-item lines="full" v-for="(file, index) in mappedFiles" :key="index" button detail @click="currentVisibleFileIndex = index">
          <ion-label class="ion-text-wrap">
            <ion-text color="primary">
              <h3>
                <span>{{ (file.modalMetadata != null) ? file.modalMetadata.date : '' }}</span>
                <span
                  :class="(file.modalMetadata != null && file.modalMetadata.previousProperties != null && file.modalMetadata.previousProperties['needs_permission'] === true) ? 'highlight-changed' : ''">
                  {{' - '}}{{ (file.needs_permission != null && file.needs_permission.type != null) ? (i18n.$t(`tools.upload-reports.permissions.${file.needs_permission.type}`) || file.needs_permission.type) : i18n.$t('tools.upload-reports.unrestricted') }}
                </span>
              </h3>
            </ion-text>
            <ion-text>
              <h2 class="file-name">{{ file.descriptor }}</h2>
            </ion-text>
          </ion-label>
        </ion-item>
        <!-- Allows scrolling beyond the list to interact with items without the FAB blocking interaction -->
        <div id="over-scroll"></div>
      </ion-list>
      <ion-fab vertical="bottom" horizontal="start" slot="fixed">
        <input ref="uploadElement" @change="readItemListFiles($event.target.files);$event.target.value = null;" type="file" accept=".json,application/json" multiple class="invisible-input"/>
        <ion-fab-button @click="uploadElement.click()" color="secondary">
          <ion-icon :icon="folderOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button color="success">
          <ion-icon :icon="ellipsisVertical"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="top">
          <ion-fab-button :disabled="mappedFiles.length <= 0 || selectedMainCategory == null" @click="openUploadConfirmation()" color="success">
            <ion-icon :icon="cloudUpload"></ion-icon>
          </ion-fab-button>
          <ion-fab-button :disabled="entryTypeIndex == null || Object.keys(entryTypeIndex).length <= 0 || true" @click="openHidingDialog()" color="success">
            <ion-icon :icon="eyeOff"></ion-icon>
          </ion-fab-button>
        </ion-fab-list>
      </ion-fab>
      <ion-modal
        :is-open="currentVisibleFileProperties != null"
        css-class="modify-item-dialog"
        @didDismiss="currentVisibleFileIndex = null">
        <ion-page>
          <ion-header>
            <ion-toolbar>
              <ion-title>{{ (currentVisibleFileProperties != null && currentVisibleFileProperties.modalMetadata != null) ? currentVisibleFileProperties.modalMetadata.date : '' }} | {{ (currentVisibleFileProperties != null) ? currentVisibleFileProperties.descriptor : '' }}</ion-title>
              <ion-buttons slot="end">
                <ion-button @click="currentVisibleFileIndex = null">{{ i18n.$t('default_interaction.close') }}</ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>
          <ion-header>
            <ion-item v-if="currentVisibleFileProperties != null">
              <ion-label position="stacked">{{ i18n.$t('tools.upload-reports.permission') }}</ion-label>
              <ion-select 
                :value="currentVisibleFilePermission"
                @ionChange="currentVisibleFilePermission = ($event.target.value != null && $event.target.value.length) ? $event.target.value : null"
                :placeholder="i18n.$t('tools.upload-reports.unrestricted')">
                <ion-select-option v-for="(permission, index) in availablePermissions" :key="index" :value="permission">{{ i18n.$t(`tools.upload-reports.permissions.${permission}`) || permission }}</ion-select-option>
                <!-- Showing permissions that can't be set here -->
                <ion-select-option 
                  v-if="currentVisibleFilePermission != null && !(availablePermissions.includes(currentVisibleFilePermission))"
                  :value="currentVisibleFilePermission">
                    {{ i18n.$t(`tools.upload-reports.permissions.${currentVisibleFilePermission}`) || currentVisibleFilePermission }}
                </ion-select-option>
                <ion-select-option :value="null">{{ i18n.$t('tools.upload-reports.unrestricted') }}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-header>
          <ion-content scroll-x="true">
            <pre>{{ (currentVisibleFileProperties != null) ? currentVisibleFileProperties.definition : '' }}</pre>
          </ion-content>
        </ion-page>
      </ion-modal>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ProgressButton id="refresh-selects-button" color="success" fill="solid" :loading="currentlyRefreshing" @click="loadExaminationStages">
          <ion-icon :icon="refresh" slot="start"></ion-icon> {{ i18n.$t('tools.upload-reports.refresh') }}
        </ProgressButton>
        <div class="metadata-selects">
          <ion-item lines="none" id="main-category-select">
            <ion-label position="stacked">{{ i18n.$t('tools.upload-reports.main_category') }}</ion-label>
            <ion-select v-model="selectedMainCategory" :disabled="localizedMainCategories == null || localizedMainCategories.length < 1" :okText="i18n.$t('default_interaction.select')" :cancelText="i18n.$t('default_interaction.cancel')" :placeholder="i18n.$t('default_interaction.select')">
              <ion-select-option v-for="option in localizedMainCategories" :key="option.id" :value="option.id">{{ option.name }}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item lines="none" id="examination-stage-select">
            <ion-label position="stacked">{{ i18n.$t('tools.upload-reports.examination_stage') }}</ion-label>
            <ion-select v-model="selectedExaminationStage" :class="selectedExaminationStage == null ? 'empty' : ''" :disabled="localizedExaminationStages == null || localizedExaminationStages.length < 1" :okText="i18n.$t('default_interaction.select')" :cancelText="i18n.$t('default_interaction.cancel')" :placeholder="i18n.$t('tools.upload-reports.no_selection')">
              <ion-select-option v-for="option in localizedExaminationStages" :key="option.id" :value="option.id">{{ option.id == null ? i18n.$t('tools.upload-reports.no_selection') : option.name }}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item lines="none" id="sub-section-select">
            <ion-label position="stacked">{{ i18n.$t('tools.upload-reports.sub_section') }}</ion-label>
            <ion-select v-model="selectedSubSection" :class="selectedSubSection == null ? 'empty' : ''" :disabled="localizedSubSections == null || localizedSubSections.length < 1" :okText="i18n.$t('default_interaction.select')" :cancelText="i18n.$t('default_interaction.cancel')" :placeholder="i18n.$t('tools.upload-reports.no_selection')">
              <ion-select-option v-for="option in localizedSubSections" :key="option.id" :value="option.id">{{ option.id == '' ? i18n.$t('tools.upload-reports.no_selection') : option.name }}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item lines="none" id="sub-category-select">
            <ion-label position="stacked">{{ i18n.$t('tools.upload-reports.sub_category') }}</ion-label>
            <ion-select v-model="selectedSubCategory" :class="selectedSubCategory == null ? 'empty' : ''" :disabled="localizedSubCategories == null || localizedSubCategories.length < 1" :okText="i18n.$t('default_interaction.select')" :cancelText="i18n.$t('default_interaction.cancel')" :placeholder="i18n.$t('tools.upload-reports.no_selection')">
              <ion-select-option v-for="option in localizedSubCategories" :key="option.id" :value="option.id">{{ option.id == '' ? i18n.$t('tools.upload-reports.no_selection') : option.name }}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item lines="none" id="section-select">
            <ion-label position="stacked">{{ i18n.$t('tools.upload-reports.section') }}</ion-label>
            <ion-select v-model="selectedSection" :class="selectedSection == null ? 'empty' : ''" :disabled="localizedSections == null || localizedSections.length < 1" :okText="i18n.$t('default_interaction.select')" :cancelText="i18n.$t('default_interaction.cancel')" :placeholder="i18n.$t('tools.upload-reports.no_selection')">
              <ion-select-option v-for="option in localizedSections" :key="option.id" :value="option.id">{{ option.id == '' ? i18n.$t('tools.upload-reports.no_selection') : option.name }}</ion-select-option>
            </ion-select>
          </ion-item>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent, IonFab, IonFabList, IonFabButton, IonIcon, IonList, IonItem, IonLabel, IonModal, IonToolbar, IonTitle, IonButtons, IonButton, IonText, IonFooter, IonSelect, IonSelectOption, IonCard, alertController, toastController } from '@ionic/vue';
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

import MainToolbar from '@/components/MainToolbar.vue';
import ProgressButton from '@/components/ProgressButton.vue';

import { folderOutline, cloudUpload, ellipsisVertical, eyeOff, refresh, informationCircleOutline } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

import { generateIdentifiersForReportType } from "@/utils/report";

import _ from 'lodash';

import { localErrorToast, apiErrorToast } from "@/utils/error";

export default  {
  name: 'UploadReports',
  components: { IonHeader, IonContent, IonPage, IonFab, IonFabList, IonFabButton, IonIcon, IonList, IonItem, IonLabel, IonModal, IonToolbar, IonTitle, IonButtons, IonButton, IonText, IonFooter, IonSelect, IonSelectOption, IonCard, MainToolbar, ProgressButton },
  setup() {
    const i18n = useI18n();

    const store = useStore();

    const uploadElement = ref(null);

    const itemListFiles = ref([]);

    const MODIFIABLE_PROPERTIES = ['order', 'needs_permission'];

    const HIGHLIGHT_PREVIOUS_PROPERTIES = ['needs_permission'];

    const currentVisibleFileIndex = ref(null);

    //Saves properties like access rights that were set by the user from the default
    const modifiedFileProperties = ref({});

    const currentlyRefreshing = ref(false);

    const loadedExaminationStages = ref(null);

    const selectedMainCategory = ref(null);

    const selectedExaminationStageRef = ref(null);

    const selectedExaminationStage = computed({
      get: () => {
        return selectedExaminationStageRef.value;
      },
      set: (newValue) => {
        //Check for any null values and set it to null
        if (newValue == null || newValue == 'undefined' || newValue == 'null' || newValue == '') {
          selectedExaminationStageRef.value = null;
        } else {
          selectedExaminationStageRef.value = newValue;
        }
        //Set the next lower level of this selection chain to null - Cascades down!
        selectedSubSection.value = null;
      }
    });

    const selectedSubSectionRef = ref(null);

    const selectedSubSection = computed({
      get: () => {
        return selectedSubSectionRef.value;
      },
      set: (newValue) => {
        //Check for any null values and set it to null
        if (newValue == null || newValue == 'undefined' || newValue == 'null' || newValue == '') {
          selectedSubSectionRef.value = null;
        } else {
          selectedSubSectionRef.value = newValue;
        }
        //Set the next lower level of this selection chain to null - Cascades down!
        selectedSubCategory.value = null;
      }
    });

    const selectedSubCategoryRef = ref(null);

    const selectedSubCategory = computed({
      get: () => {
        return selectedSubCategoryRef.value;
      },
      set: (newValue) => {
        //Check for any null values and set it to null
        if (newValue == null || newValue == 'undefined' || newValue == 'null' || newValue == '') {
          selectedSubCategoryRef.value = null;
        } else {
          selectedSubCategoryRef.value = newValue;
        }
        //Set the next lower level of this selection chain to null - Cascades down!
        selectedSection.value = null;
      }
    });

    const selectedSectionRef = ref(null);

    const selectedSection = computed({
      get: () => {
        return selectedSectionRef.value;
      },
      set: (newValue) => {
        //Check for any null values and set it to null
        if (newValue == null || newValue == 'undefined' || newValue == 'null' || newValue == '') {
          selectedSectionRef.value = null;
        } else {
          selectedSectionRef.value = newValue;
        }
      }
    });

    const INTERNAL_PERMISSION = 'Internal';

    const availablePermissions = [INTERNAL_PERMISSION];

    //Includes all current versions that are visible and valid
    const entryTypeIndex = computed(() => {
      return store.getters['reports/getReportTypeIndex'];
    });

    const getLocalizedMainCategory = computed(() => function(mainCategoryId){
      let categoryNames = store.getters['reports/getMainCategoryNamesById'](mainCategoryId);
      if (categoryNames) {
        return categoryNames[i18n.locale.value];
      } else {
        return null;
      }
    });

    const localizedMainCategories = computed(() => {
      let categories = store.getters['reports/getMainCategories'];
      let language = i18n.locale.value;
      if (categories != null) {
        return _.sortBy(Object.values(categories).map(category => {
          return {
            id: category.id,
            name: (category.name != null && (language in category.name)) ? category.name[language] : null,
            order: category.order
          };
        }), 'order');
      } else {
        return null;
      }
    });

    const localizedExaminationStages = computed(() => {
      let language = i18n.locale.value;
      if (loadedExaminationStages.value != null && Array.isArray(loadedExaminationStages.value)) {
        return _.unionBy([{id: null, name: '', order: 0}], _.sortBy(loadedExaminationStages.value.map(examinationStage => {
          return {
            id: examinationStage.id,
            name: (examinationStage.name != null && (language in examinationStage.name)) ? examinationStage.name[language] : examinationStage.descriptor,
            order: examinationStage.order
          };
        }), 'order'), 'id');
      } else {
        return null;
      }
    });

    const selectedExaminationStageProperties = computed(() => {
      if (loadedExaminationStages.value != null && Array.isArray(loadedExaminationStages.value) && selectedExaminationStage.value != null) {
        return _.first(_.filter(loadedExaminationStages.value, (examinationStage) => examinationStage.id == selectedExaminationStage.value)) || null;
      } else {
        return null;
      }
    });

    const localizedSubSections = computed(() => {
      let language = i18n.locale.value;
      if (selectedExaminationStageProperties.value != null && selectedExaminationStageProperties.value.sub_categories != null) {
        //Map all options for localized display name. Always include empty string as "no_selection"!
        return _.unionBy([{id: '', name: '', order: 0}], _.sortBy(_.map(selectedExaminationStageProperties.value.sub_categories, (subSection, subSectionDescriptor) => {
          return {
            id: subSectionDescriptor,
            name: (subSection.name != null && (language in subSection.name)) ? subSection.name[language] : subSectionDescriptor,
            order: subSection.order
          };
        }), 'order'), 'id');
      } else {
        return null;
      }
    });

    const selectedSubSectionProperties = computed(() => {
      if (selectedExaminationStageProperties.value != null && selectedExaminationStageProperties.value.sub_categories != null) {
        return selectedExaminationStageProperties.value.sub_categories[(selectedSubSection.value || '')] || null;
      } else {
        return null;
      }
    });

    const localizedSubCategories = computed(() => {
      let language = i18n.locale.value;
      if (selectedSubSectionProperties.value != null && selectedSubSectionProperties.value.categories != null) {
        //Map all options for localized display name. Always include empty string as "no_selection"!
        return _.unionBy([{id: '', name: '', order: 0}], _.sortBy(_.map(selectedSubSectionProperties.value.categories, (subCategory, subCategoryDescriptor) => {
          return {
            id: subCategoryDescriptor,
            name: (subCategory.name != null && (language in subCategory.name)) ? subCategory.name[language] : subCategoryDescriptor,
            order: subCategory.order
          };
        }), 'order'), 'id');
      } else {
        return null;
      }
    });

    const selectedSubCategoryProperties = computed(() => {
      if (selectedSubSectionProperties.value != null && selectedSubSectionProperties.value.categories != null) {
        return selectedSubSectionProperties.value.categories[(selectedSubCategory.value || '')] || null;
      } else {
        return null;
      }
    });

    const localizedSections = computed(() => {
      let language = i18n.locale.value;
      if (selectedSubCategoryProperties.value != null && selectedSubCategoryProperties.value.sections != null) {
        //Map all options for localized display name. Always include empty string as "no_selection"!
        return _.unionBy([{id: '', name: '', order: 0}], _.sortBy(_.map(selectedSubCategoryProperties.value.sections, (section, sectionDescriptor) => {
          return {
            id: sectionDescriptor,
            name: (section.name != null && (language in section.name)) ? section.name[language] : sectionDescriptor,
            order: section.order
          };
        }), 'order'), 'id');
      } else {
        return null;
      }
    });

    const readItemListFiles = function(files){
      let filePromises = [];
      for (let file of files){
        try {
          //Remove file extension
          let fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');

          //Split up the string in date and the rest
          let fileNameParts = /^([0-9]{1,4}-[0-9]{1,2}-[0-9]{1,2}) (.*)$/.exec(fileNameWithoutExtension);

          if (fileNameParts == null || fileNameParts.length < 3 || fileNameParts[1] == null || fileNameParts[1].length == 0 || fileNameParts[2] == null || fileNameParts[2].length == 0) 
            throw { name: 'InvalidFilename', message: i18n.$t('tools.upload-reports.invalid_filename') + ' | ' + fileNameWithoutExtension };
          
          //Date at beginning of filename
          let entryDate = fileNameParts[1];
          //Rest of the filename trimmed
          let entryName = fileNameParts[2].trim();

          //Split up the date in its parts
          let dateParts = /^([0-9]{1,4})-([0-9]{1,2})-([0-9]{1,2})$/.exec(entryDate);

          if (dateParts == null || dateParts.length < 4 || dateParts[1] == null || dateParts[2] == null || dateParts[3] == null) 
            throw { name: 'InvalidDateFormat', message: i18n.$t('tools.upload-reports.invalid_date_format') + ' | ' + entryDate };

          let version = dateParts[1].padStart(4, '2021') + 
                        dateParts[2].padStart(2, '00') + 
                        dateParts[3].padStart(2, '00');

          let reader = new FileReader(); 
          filePromises.push(new Promise((resolve, reject) => { 
            reader.onload = function(){ 
              try {
                resolve({
                  name: entryName,
                  date: entryDate,
                  version: version,
                  contents: JSON.parse(reader.result),
                  permission: INTERNAL_PERMISSION
                });
              } catch (error) {
                reject(error);
              }
            } 
          }));
          
          reader.readAsText(file);
        } catch (error) {
          console.error(error);
          localErrorToast(i18n, i18n.$t('tools.upload-reports.load_error'), error.message);
        }
      }

      Promise.all(filePromises)
      .then((newFiles) => {
        itemListFiles.value = newFiles;
      })
      .catch((error) => {
        console.error(error);
        localErrorToast(i18n, i18n.$t('tools.upload-reports.load_error'), error.message);
      })
    }

    const resetForm = function(){
      itemListFiles.value = [];
      selectedMainCategory.value = null;
      currentVisibleFileIndex.value = null;
    }

    const filesWithIdentifiers = ref([]);
    watch([itemListFiles, selectedMainCategory, selectedExaminationStageProperties, selectedSubSection, selectedSubCategory, selectedSection],
          ([newFiles, newMainCategory, newExaminationStageProperties, newSubSection, newSubCategory, newSection]) => {     
      let fileProcessingPromises = _.map(newFiles, (file) => {
        //Return an async function that gets called immediately to create a promise
        return (async () => {
          let type = {
            descriptor: file.name,
            version: file.version,
            definition: file.contents,
            main_category: newMainCategory,
            examination_stage: newExaminationStageProperties,
            sub_section: newSubSection,
            sub_category: newSubCategory,
            section: newSection,
            order: 0,
            needs_permission: (file.permission != null) ? { type: file.permission } : undefined,
            modalMetadata: {
              date: file.date
            } //To store all additional data that is not necessary for the actual upload
          };

          //Generate and set the shortIdentifier from the given type - Shallow clone so further changes don't affect it
          let { shortIdentifier } = await generateIdentifiersForReportType({...type});
          type.modalMetadata.shortIdentifier = shortIdentifier;

          //Reset examination_stage to only include the ID
          if (type.examination_stage != null) type.examination_stage = type.examination_stage.id;

          return type;
        })();
      });

      //Map all files to get shortIdentifier and set the ordering properties - Promise.all for generating the identifiers asynchronously
      Promise.all(fileProcessingPromises)
      .then((processedFiles) => filesWithIdentifiers.value = processedFiles)
      .catch((error) => {
        //On error notify user and just return an empty array
        console.error(error);
        localErrorToast(i18n, i18n.$t('tools.upload-reports.load_error'), error.message);
        return [];
      });
    });

    const mappedFiles = computed(() => {
      //Set parameters from previous version, if any files exist!
      if (filesWithIdentifiers.value != null && Array.isArray(filesWithIdentifiers.value) && filesWithIdentifiers.value.length > 0) {
        return _.map(filesWithIdentifiers.value, (file) => {
          let shortIdentifier = file.modalMetadata.shortIdentifier;

          let mappedFile = {
            ...file,
            modalMetadata: { //Copy metadata
              ...file.modalMetadata,
              previousProperties: {} //Create an object for all properties that are from previous versions (set to true)
            }
          };

          //All previously set properties for this entry type that get picked here, will be applied to the mapped file if they are set, but can be null too
          if (shortIdentifier != null && entryTypeIndex.value != null && shortIdentifier in entryTypeIndex.value) {
            let previousProperties = _.pick(entryTypeIndex.value[shortIdentifier], MODIFIABLE_PROPERTIES);
            _.forEach(previousProperties, (property, propertyKey) => {
              if (property !== undefined) {
                //Map permissions differently to match the object type that is required
                if (propertyKey === 'needs_permission') mappedFile[propertyKey] = (property != null) ? { type: property } : null;
                else mappedFile[propertyKey] = property;
                mappedFile.modalMetadata.previousProperties[propertyKey] = true; //Set that it was from the previous properties to highlight in the UI
              }
            });
          }

          //All by the user modified properties that get picked here, will be applied to the mapped file if they are set, but can be null too!
          if (file.descriptor != null && modifiedFileProperties.value != null && file.descriptor in modifiedFileProperties.value) {
            let modifiedProperties = _.pick(modifiedFileProperties.value[file.descriptor], MODIFIABLE_PROPERTIES);
            _.forEach(modifiedProperties, (property, propertyKey) => {
              if (property !== undefined) {
                mappedFile[propertyKey] = property;
                delete mappedFile.modalMetadata.previousProperties[propertyKey]; //Unset that it was from the previous properties
              }
            });
          }

          return mappedFile;
        });
      }

      return [];
    });

    const anyFilePropertiesByPreviousVersion = computed(() => {
      //Check for each file if any of the previous properties return true
      return _.some(mappedFiles.value, (file) => {
        if (file.modalMetadata != null && file.modalMetadata.previousProperties != null) {
          for (let property of HIGHLIGHT_PREVIOUS_PROPERTIES) {
            if (file.modalMetadata.previousProperties[property] === true) return true;
          }
        }
        return false;
      });
    });

    const currentVisibleFileProperties = computed({
      get: () => {
        if (currentVisibleFileIndex.value != null && mappedFiles.value != null && currentVisibleFileIndex.value < mappedFiles.value.length) {
          return mappedFiles.value[currentVisibleFileIndex.value];
        }

        return null;
      },
      set: (newProperties) => {
        if (currentVisibleFileIndex.value != null && mappedFiles.value != null && currentVisibleFileIndex.value < mappedFiles.value.length) {
          //Set the properties at the current selected descriptor, if it is valid
          let descriptor = mappedFiles.value[currentVisibleFileIndex.value].descriptor;
          if (descriptor != null) {
            //Either load the properties that are already set or take an empty object
            let existingModifiedProperties = modifiedFileProperties.value[descriptor] || {};
            //Set all existing values again and overwrite with new ones. Create a shallow copy for re-setting so it always triggers computed
            modifiedFileProperties.value = {
              ...modifiedFileProperties.value,
              [descriptor]: {
                ...existingModifiedProperties,
                ...newProperties
              }
            }
          }
        }
      }
    });

    const currentVisibleFilePermission = computed({
      get: () => {
        let properties = currentVisibleFileProperties.value;
        if (properties != null && properties.needs_permission != null) return properties.needs_permission.type;
        return null;
      },
      set: (newPermission) => {
        currentVisibleFileProperties.value = {
          needs_permission: (newPermission != null) ? { type: newPermission } : null
        };
      }
    });

    const uploadItemLists = async function(){
      let creationPromises = [];

      for (let file of mappedFiles.value){
        //Clone and remove metadata that is not necessary for the upload itself
        let type = _.omit(_.cloneDeep(file), 'modalMetadata');

        creationPromises.push(
          store.dispatch('reports/createNewReportType', type)
        );
      }

      Promise.all(creationPromises).then(() => {
        toastController.create(
          {
            message: i18n.$t('tools.upload-reports.uploaded'),
            position: 'top',
            duration: 5000,
            color: 'success',
            buttons: [
              {
                text: i18n.$t('default_interaction.close'),
                role: 'cancel'
              }
            ]
          }
        ).then((toast) => {
          toast.present();
          resetForm();
        });
      })
      .catch((error) => {
        apiErrorToast(i18n, error);
      })
      .finally(() => {
        store.dispatch('reports/fetchReportTypeIndex'); //Update index, because something must have changed during the update
      });
    }          

    const hideEntryTypes = function(entryTypes){
      let hidePromises = [];

      for (let entryTypeArray of entryTypes){
        for (let entryType of entryTypeArray) {
          hidePromises.push(
            store.dispatch('reports/hideReportType', entryType.id)
          );
        }
      }

      Promise.all(hidePromises).then(() => {
        toastController.create(
          {
            message: i18n.$t('tools.upload-reports.hidden'),
            position: 'top',
            duration: 5000,
            color: 'success',
            buttons: [
              {
                text: i18n.$t('default_interaction.close'),
                role: 'cancel'
              }
            ]
          }
        ).then((toast) => {
          toast.present();
        });
      })
      .catch((error) => {
        apiErrorToast(i18n, error);
      })
      .finally(() => {
        store.dispatch('reports/fetchReportTypeIndex'); //Update index, because something must have changed during the update
      });
    }

    const formatEntryTypeCategoryPath = computed(() => {
      let language = i18n.locale.value;
      const joinCharacter = ' -> ';
      const emptyPlaceholder = '*';
      return function(categoryPath, includeEmpty = true) {
        if (categoryPath == null) return '';
        return _.join(_.compact(_.map(categoryPath, (pathSection) => {
          if (pathSection != null) {
            //If it already is a string, return it
            if (_.isString(pathSection)) return pathSection;
            //Otherwise extract name from options
            if (pathSection.name != null && pathSection.name[language] != null) {
              return pathSection.name[language];
            }
            if (pathSection.descriptor != null) {
              return pathSection.descriptor; //If null, it will be filtered out by compact
            }
          }
          //If not returned yet, decide if empty one should be included or replaced by a placeholder
          if (includeEmpty) return emptyPlaceholder;
          return null;
        })), joinCharacter);
      }
    });

    const openUploadConfirmation = async function(){
        const alert = await alertController
        .create({
          cssClass: 'upload-confirmation-alert',
          header: i18n.$t('tools.upload-reports.upload-confirmation.title'),
          message:  i18n.$t('tools.upload-reports.upload-confirmation.message.before') + 
                    '<b>' + mappedFiles.value.length + '</b>' + 
                    i18n.$t('tools.upload-reports.upload-confirmation.message.after') + 
                    ' <br/><br/> ' + i18n.$t('tools.upload-reports.upload-confirmation.message.category') + 
                    '<b>' + getLocalizedMainCategory.value(selectedMainCategory.value) + '</b>' + 
                    ' <br/><br/> ' + i18n.$t('tools.upload-reports.upload-confirmation.message.category_hierarchy') + 
                    '<b>' + formatEntryTypeCategoryPath.value([selectedExaminationStageProperties.value, selectedSubSectionProperties.value, selectedSubCategoryProperties.value, selectedSection.value]) + '</b>',
          buttons: [
            {
              text: i18n.$t('default_interaction.cancel'),
              role: 'cancel'
            },
            {
              text: i18n.$t('tools.upload-reports.upload-confirmation.upload'),
              cssClass: 'upload-confirmation-okay',
              handler: () => {
                uploadItemLists();
              },
            },
          ],
        });
      return alert.present();
    }

    const loadExaminationStages = function() {
      currentlyRefreshing.value = true;
      store.dispatch('reports/fetchExaminationStages').then((result) => loadedExaminationStages.value = result)
      .catch((error) => {
        apiErrorToast(i18n, error);
      })
      .finally(() => currentlyRefreshing.value = false);
    }
    
    onMounted(() => {
      loadExaminationStages();
    })

    const openHidingDialog = async function(){
        const alert = await alertController
        .create({
          cssClass: 'hiding-dialog-alert',
          header: i18n.$t('tools.upload-reports.hiding-dialog.title'),
          inputs: Object.values(entryTypeIndex.value).map((entryType) => {
            return {
              type: 'checkbox',
              label: `${getLocalizedMainCategory.value(entryType.main_category)} | ${formatEntryTypeCategoryPath.value(entryType.category_hierarchy, false)} | ${entryType.descriptor}`,
              value: entryType.versions, //FIXME This data is not sufficient for hiding all versions! We only save the newest one! API should provide hiding rout to hide all for the same path! No matter which version! API also doesn't need to return every version of old types!
              cssClass: 'select-entry-item'
            }
          }),
          buttons: [
            {
              text: i18n.$t('default_interaction.cancel'),
              role: 'cancel'
            },
            {
              text: i18n.$t('tools.upload-reports.hiding-dialog.hide'),
              cssClass: 'hiding-dialog-okay',
              handler: (data) => {
                hideEntryTypes(data);
              },
            },
          ],
        });
      return alert.present();
    }

    return {
      i18n,
      uploadElement,
      mappedFiles,
      anyFilePropertiesByPreviousVersion,
      currentVisibleFileIndex,
      currentVisibleFileProperties,
      currentVisibleFilePermission,
      loadExaminationStages,
      currentlyRefreshing,
      entryTypeIndex,
      readItemListFiles,
      openUploadConfirmation,
      openHidingDialog,
      localizedMainCategories,
      localizedExaminationStages,
      localizedSubCategories,
      localizedSubSections,
      localizedSections,
      selectedMainCategory,
      selectedExaminationStage,
      selectedSubCategory,
      selectedSubSection,
      selectedSection,
      availablePermissions,
      folderOutline,
      cloudUpload,
      ellipsisVertical,
      eyeOff,
      refresh,
      informationCircleOutline
    };
  }
}
</script>

<style>
.upload-confirmation-okay, .hiding-dialog-okay {
  color: var(--ion-color-success-shade)!important;
}

/* Allow for longer texts in select and indent every line but the first using a negative offset on the first line in a wrapped text */
.select-entry-item .alert-radio-label, .select-entry-item .alert-checkbox-label {
  margin-left: 6px;
  white-space: normal!important;
  text-indent: -6px;
}

/* Fix that the radio buttons are displayed, even when wrapping more than two lines */
.select-entry-item .select-interface-option {
  height: auto;
  contain: content;
}

.select-entry-item {
  height: auto!important;
  contain: content!important;
}

.hiding-dialog-alert {
  --max-width: 80%;
  --max-height: 80%;
  --height: auto;
}

.hiding-dialog-alert .alert-checkbox-group {
  max-height: unset;
}
</style>

<style scoped>
ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

.information-container {
  z-index: 1001;
  left: 0px;
  top: 10px;
  position: sticky;
  display: flex;
  flex-flow: row;
  align-items: center;
  padding-right: 10px;
}

.information-container > * {
  opacity: 1!important;
  padding: 10px 0px 10px 10px;
}

.information-container ion-icon {
  min-width: 24px;
  max-width: 24px;
}

.highlight-changed {
  color: var(--ion-color-warning-shade);
}

pre {
  padding: 16px;
  position: absolute;
  overflow: visible;
  margin: 0px;
}

#over-scroll {
  width: 100%;
  height: 100px;
}

.invisible-input {
  visibility: hidden;
  display: none;
}

.file-name {
  padding-top: 5px;
}

.metadata-selects {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px 10px;
  counter-reset: selectindex;
}

.metadata-selects > * {
  flex-grow: 1;
  flex-basis: 300px;
  border-width: 0px 1px 1px 1px;
  min-width: 300px;
  margin: 2px 0px;
  margin-left: -1px;
  border-style: solid;
  border-color: var(--ion-color-medium);
  --background: var(--background);
}

.metadata-selects > * > ion-label::before {
  counter-increment: selectindex;
  content: counter(selectindex) " | ";
}

.metadata-selects > * > .empty:not(.select-disabled) {
  opacity: var(--placeholder-opacity);
}

.metadata-selects > * > .empty:not(.select-disabled)::part(placeholder) {
 opacity: 1;
}

.metadata-selects > * > .select-disabled {
  opacity: 1;
}

#refresh-selects-button {
  text-transform: none;
  margin: 5px 10px;
}

ion-fab-button.fab-button-disabled {
  opacity: 0.5;
}
</style>
